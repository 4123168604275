import { Link } from "gatsby";
import React from "react";
import { LayoutBox, LayoutBoxMinimal } from "../../components/atoms/LayoutBox";
import { TextAndImageSection } from "../../components/molecules/TextAndImageSection";
import { TextSection } from "../../components/molecules/TextSection";
import ToolsRoll from "../../components/ToolsRoll";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import { TextBanner } from "../../components/molecules/TextBanner";

export const ForProfessionalsPageTemplate = ({
  title,
  description,
  topPanel,
  content,
  buttonText,
  buttonLink,
}) => {
  return (
    <>
      <div className="hero-professional is-medium">
        <div className="hero-body pl-6 pr-6">
          <LayoutBoxMinimal>
            <div className="columns is-multiline">
              <div className="column is-9">
                <div className="section p-0 pb-3">
                  <h1
                    className="title is-size-3-mobile is-size-2-tablet is-size-1-widescreen pl-0 pr-0"
                    style={{
                      color: "white",
                      lineHeight: "1.2",
                    }}
                  >
                    {title}
                  </h1>
                </div>
              </div>
              <div className="column is-4">
                <div className="section p-0 pr-6">
                  <h2
                    className="subtitle has-text-weight-normal is-size-5-mobile is-size-5-tablet is-size-4-widescreen pl-0 pr-0"
                    style={{
                      color: "white",
                      lineHeight: "1.4",
                      paddingBottom: "6rem",
                    }}
                  >
                    {description}
                  </h2>
                </div>
              </div>
            </div>
          </LayoutBoxMinimal>
        </div>
      </div>
      <TextSection mainText={topPanel.title}>
        <div className="columns is-centered">
          <div className="column is-one-quarter">
            <Link
              to="/onboarding"
              className="button is-primary is-rounded is-large is-fullwidth"
            >
              Get Started
            </Link>
          </div>
        </div>
      </TextSection>
      {content.sections.map((s, idx) => {
        const hasAltBackgroundColor = idx % 2 === 0;
        const isReverseOrder = idx % 2 === 0;
        return s.image ? (
          <TextAndImageSection
            image={s.image}
            title={s.title}
            mainText={s.description}
            hasAltBackgroundColor={hasAltBackgroundColor}
            isReverseOrder={isReverseOrder}
          >
            {s.hasButton && (
              <div className="columns is-centered pt-4">
                <div className="column is-6 pt-0">
                  <Link to={buttonLink} target="_blank">
                    <button className="button is-primary is-rounded is-large is-fullwidth">
                      {buttonText}
                    </button>
                  </Link>
                </div>
              </div>
            )}
            {s.readMoreLink && (
              <div className="columns">
                <div className="column">
                  <Link to={s.readMoreLink}>
                    <button className="button button is-rounded is-medium has-text-info">
                      Read More →
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </TextAndImageSection>
        ) : s.centralImage ? (
          <>
            <LayoutBox hasAltBackgroundColor={hasAltBackgroundColor}>
              <h1
                className="title is-size-4-mobile is-size-3-tablet is-size-2-widescreen pb-3"
                style={{
                  lineHeight: "1",
                }}
              >
                {s.title}
              </h1>
              <h2
                className="subtitle has-text-weight-light is-size-6-mobile is-size-6-tablet is-size-5-widescreen"
                style={{
                  lineHeight: "1.4",
                }}
              >
                {s.description}
              </h2>
            </LayoutBox>
            <LayoutBox>
              <div className="level is-align-items-flex-start">
                <div className="level-item is-flex-shrink-3">
                  <div className="content">
                    <h1 className="has-text-primary has-text-weight-light">
                      neumind app
                    </h1>
                    <h5 className="has-text-primary has-text-weight-light">
                      For patients and caregivers
                    </h5>
                    <ul className="has-text-weight-light">
                      <li>Tools to support cognition and independence</li>
                      <li>
                        Personalised strategies and education in their pocket
                      </li>
                      <li>
                        Connect, educate, and co-ordinate family and carers
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="level-item px-3 is-flex-shrink-1">
                  <PreviewCompatibleImage
                    imageInfo={{ image: s.centralImage }}
                  />
                </div>
                <div className="level-item is-flex-shrink-3">
                  <div className="content">
                    <h1 className="has-text-primary has-text-weight-light">
                      Clinical Dashboard
                    </h1>
                    <h5 className="has-text-primary has-text-weight-light">
                      For professionals
                    </h5>
                    <ul className="has-text-weight-light">
                      <li>
                        Deliver and reinforce therapies outside the clinic
                      </li>
                      <li>
                        Streamline clinical processes and reduce admin time
                      </li>
                      <li>
                        Monitor progress and get real-time data and insights
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {s.hasButton && (
                <div className="columns is-centered pt-4">
                  <div className="column is-3 pt-0 pb-0">
                    <Link
                      to="/onboarding"
                      className="button is-primary is-rounded is-large is-fullwidth"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              )}
            </LayoutBox>
          </>
        ) : (
          <TextSection
            title={s.title}
            mainText={s.description}
            hasAltBackgroundColor={hasAltBackgroundColor}
          >
            {s.hasButton && (
              <div className="columns is-centered">
                <div className="column is-3 pt-4">
                  <Link to={buttonLink} target="_blank">
                    <button className="button is-primary is-rounded is-large is-fullwidth">
                      {buttonText}
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </TextSection>
        );
      })}

      <TextBanner title="Our Focus Areas" />
      <LayoutBox>
        <ToolsRoll />
      </LayoutBox>
    </>
  );
};
