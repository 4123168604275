import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { LegoBlock } from "./atoms/LegoBlock";

export const ToolPreview = ({ tool }) => {
  const featuredImage =
    getImage(tool.frontmatter.featuredimage) || tool.frontmatter.featuredimage;

  return (
    <LegoBlock backgroundColor={featuredImage?.backgroundColor || "lightblue"}>
      <div className="columns is-vcentered is-mobile my-auto">
        <div className="column is-5">
          <figure className="image">
            {typeof featuredImage === "object" &&
            featuredImage.extension !== "svg" ? (
              <GatsbyImage
                image={featuredImage}
                alt={`${tool.frontmatter.title} Icon`}
              />
            ) : (
              <img
                src={featuredImage.publicURL || featuredImage}
                alt={`${tool.frontmatter.title} Icon`}
              />
            )}
          </figure>
        </div>
        <div className="column is-7">
          <div className="card-header-title pl-0 pr-0 pb-0">
            <h1 className="title is-size-6-mobile is-size-5-tablet is-size-4-widescreen">
              {tool.frontmatter.title}
            </h1>
          </div>

          <div className="card-header-title pl-0 pr-0 pt-1">
            <h2 className="subtitle is-size-6-mobile is-size-6-tablet is-size-5-widescreen has-text-grey">
              {tool.frontmatter.description}
            </h2>
          </div>
        </div>
      </div>
      <footer className="card-footer is-flex-direction-row-reverse">
        <Link
          to={tool.fields.slug}
          className="is-flex is-align-items-center py-3"
        >
          Read More →
        </Link>
      </footer>
    </LegoBlock>
  );
};
